exports.components = {
  "component---src-page-templates-article-js": () => import("./../../../src/page-templates/article.js" /* webpackChunkName: "component---src-page-templates-article-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelt-js": () => import("./../../../src/pages/aktuelt.js" /* webpackChunkName: "component---src-pages-aktuelt-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ledige-stillinger-js": () => import("./../../../src/pages/ledige-stillinger.js" /* webpackChunkName: "component---src-pages-ledige-stillinger-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-tjenester-js": () => import("./../../../src/pages/tjenester.js" /* webpackChunkName: "component---src-pages-tjenester-js" */)
}

